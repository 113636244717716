<template>
  <div ref="container" class="relative overflow-visible">
    <InputText
      v-model="search"
      :label="label || $t('general.search')"
      :icon="loading ? 'loading' : 'search'"
      :dark="dark"
      @focus="active = true"
    />
    <div v-if="resultsVisible" class="absolute right-0 top-full z-top w-max">
      <div
        class="py-6 shadow-lg"
        :class="{
          'bg-black text-white': dark,
          'bg-white text-gray-500': !dark,
        }"
      >
        <span
          class="px-8 text-md font-bold"
          :class="{
            'text-black': !dark,
            'text-white': dark,
          }"
        >
          {{ $t('search.products') }}
        </span>
        <div class="mt-2 flex flex-col">
          <slot
            v-for="product in products?.slice(0, 6)"
            :key="product.id"
            name="product"
            :product="product"
          >
            <div
              class="cursor-pointer px-8 py-2 transition-all duration-200"
              :class="{
                'hover:bg-black hover:text-white': !dark,
                'hover:bg-white hover:text-black': dark,
              }"
              @click="selectProduct(product)"
            >
              <div class="flex gap-2">
                <ShopSharedImage
                  :media="product.cover?.media"
                  class="size-12"
                />
                <div class="flex flex-col">
                  <span class="text-sm font-bold">
                    {{ getTranslatedProperty(product, 'name') }}
                  </span>
                  <div class="flex items-center gap-2 text-xs">
                    <span>{{ product.productNumber }}</span>
                    <span>|</span>
                    <ShopProductPrice
                      v-if="!product.extensions?.hiddenPrice?.isHiddenPrice"
                      :product="product"
                    />
                    <span v-else>
                      {{ $t('product.hiddenPrice') }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </slot>
        </div>
        <div class="mt-2 px-8">
          <AppLink class="text-white hover:text-white" @click="showAllResults">
            {{ $t('search.showAllResults') }}
          </AppLink>
        </div>
      </div>
    </div>
    <div
      v-else-if="noResultsVisible"
      class="absolute top-full z-40 w-full p-4 shadow-lg"
      :class="{
        'bg-black text-white': dark,
        'bg-white text-gray-500': !dark,
      }"
    >
      <div class="text-sm">
        {{ $t('general.noResults') }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { getTranslatedProperty } from '@shopware/helpers'
import { useSearch } from '~/composables/useSearch'
import type { Schemas } from '#shopware'

const { user } = useUser()
const { isProfessional } = useCustomer(user)

const props = withDefaults(
  defineProps<{
    label?: string
    dark?: boolean
    articleSearch?: boolean
  }>(),
  {
    dark: false,
    articleSearch: false,
  }
)
const emit = defineEmits(['select'])

const container = ref<HTMLElement>()

const active = ref(false)

const search = ref<string>('')
const { products, status, refresh } = useSearch(
  search,
  props.articleSearch,
  isProfessional.value
)
const loading = computed(() => status.value === 'pending')
const hasResults = computed(() => !!products.value?.length && !loading.value)

onClickOutside(container, () => {
  active.value = false
})

const resultsVisible = computed(() => active.value && hasResults.value)

const noResultsVisible = computed(
  () =>
    active.value && !hasResults.value && search.value.length && !loading.value
)

const showAllResults = () => {
  navigateTo({ path: '/search', query: { search: search.value } })
  active.value = false
}

const selectProduct = (product: Schemas['Product']) => {
  emit('select', product)
  search.value = ''
  active.value = false
}

watchDebounced(search, refresh, { debounce: 500 })
</script>
